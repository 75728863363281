import React, { Component } from 'react'
import { Link } from "react-router-dom";

export class Navbar extends Component {
  triggerAppStateNavToggle = () => {
    this.props.triggerAppStateNavToggle(false);
  }
  
  render() {
    // const {h1, h1up, h2, h2bold, p, link, navlink, captionlink} = this.props.type
    const { h2 } = this.props.type
    const { navHeight } = this.props

    return (
      <nav className={navHeight + "mb4 mr1 pr3-l pt10 pt20-l"}>
        <div className="flex justify-end">
          <span className="f3 ttu">
          <a onClick={this.triggerAppStateNavToggle} className={h2 + " pointer ttu black"}>Menu</a></span>
        </div>
        {navHeight 
          ?
            <ul className="pl0 nt30">
              <li className={h2 + " pointer list ttu black"}>
              <Link to="/" onClick={this.triggerAppStateNavToggle} className="black link">Home</Link>
              </li>
              <li className={h2 + " pointer list ttu black"}>
                <Link to="/blankit" onClick={this.triggerAppStateNavToggle} className="black link">Blankit</Link>
              </li>
            </ul>
          : 
            null
        }
      </nav>
    )
  }
}

export default Navbar
