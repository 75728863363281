import React, { Component } from 'react'
import ImageRow from '../components/ImageRow'
import TextColumnIntro from '../components/TextColumnIntro'
import TextColumnText from '../components/TextColumnText'

// markdown
import BlankitCopy from '../copy/blankitCopy'

// images
let blankitLogo = require('../img/blankit-logo.png')
let blankitPreview = require('../img/blankit-layout.png')
let appLink = require('../img/blankit-layout.png')

   
export class Blankit extends Component {
  render() {
    return (
      <div className="nt30">
        <TextColumnIntro 
          type={this.props.type} 
          heading="Blankit" 
          subheading="An app that increases productivity and blocks out distractions."
          published="Published on the 20th of November 2019"
        />
        <a href="https://apps.apple.com/us/app/blankit/id1489001593" className="ttu black b2" target="_blank">
          Download from the Apple App Store >></a>
        <TextColumnText 
          type={this.props.type} 
          texty={BlankitCopy}
        />
        <ImageRow 
          image1={blankitLogo} 
          image2={blankitPreview} 
        />
      </div>
    )
  }
}

export default Blankit