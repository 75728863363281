import React, { Component } from 'react'

export class TextColumn extends Component {
  render() {
    
    // const {h1, h1up, h2, h2bold, p, link, navlink, captionlink} = this.props.type
    const {h1, h2, link, p } = this.props.type
    const {heading, subheading, published} = this.props
    
    return (
      <div className="flex flex-column">
        <div className="w-80 w-50-l pr4">
          <h2 className={h1 + " mb3 mt0 pt20 bt bw2 ttu"}>{heading}</h2>
          <h3 className={h2 + " mb3 mt0 ttu"}>{subheading}</h3>
          {
            published 
              ? <p className={link + " mb5 mt0 ttu"}>{published}</p>
              : null
          }
        </div>
      </div>
    )
  }
}

export default TextColumn
