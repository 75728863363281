import React, { Component } from 'react'
import { Link } from "react-router-dom"

var globe1 = require('../img/globe1.png')
var globe2 = require('../img/globe2.png')
var globe3 = require('../img/globe3.png')
 
 
export class Homepage extends Component {

  constructor() {
    super()
    this.state = {
      imagee: null
    }
  }

  async componentDidMount() {

    // console.log('shagger')
 
    // let looper = [globe1, globe2, globe3]

    // let yin = null 

    // let x = 0
    // looper.forEach((loop, i) => {
    //   if
    //   yin = looper[1]
    //   x++
    // })

    // await this.setState({
    //   imagee: yin
    // })
  
  }
 
  render() {
    // const {h1, h1up, h2, h2bold, p, link, navlink, captionlink} = this.props.type
    const {h1, h2 } = this.props.type
    return (
      <div className="flex flex-column flex-row-l nt30">
        <div className="w-50 pr4">
          <h2 className={h1 + " mb3 mt0 pt3 bt bw2 ttu"}>Introduction</h2>
          <Link to="/blankit" className={h2 + " black ttu i mt30"}>1. Blankit</Link>
        </div>
        <div className="flex justify-center w-100 w-50-l mt30">
          <span className="f5 fw-300 lh-copy mt0-ns flex items-start justify-start">
            <img className="db w-80" src={globe1} alt="Blankit logo and outline" />
          </span>
        </div>
      </div>
    )
  }
}

export default Homepage
