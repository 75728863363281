import React, { Component } from 'react'

export class ImageRow extends Component {
  render() {
    const {image1, image2} = this.props
    return (
      <div className="f5 f3-m lh-copy mt5">
        <div className="flex">
          <div className="fl w-100 w-50-l pr2-l pb3">
            <img className="db w-100" src={image1} alt="Blankit logo and outline" />
          </div>
          <div className="fl w-50 w-50-l pr1 pr0-l pl2-l pb3">
            <img className="db w-100" src={image2} alt="Homescreen layout of Blankit" />
          </div>
        </div>
      </div>
    )
  }
}

export default ImageRow
