import React, { Component } from 'react'
import TextColumnIntro from '../components/TextColumnIntro'
import TextColumnText from '../components/TextColumnText'

// markdown
import BlankitPrivacyCopy from '../copy/blankitPrivacyCopy'
   
export class BlankitPrivacy extends Component {
  render() {
    return (
      <div className="nt30">
        <TextColumnIntro 
          type={this.props.type} 
          heading="Blankit" 
          subheading="Privacy Policy" 
        />
        <TextColumnText 
          type={this.props.type} 
          texty={BlankitPrivacyCopy}
        />
      </div>
    )
  }
}

export default BlankitPrivacy
