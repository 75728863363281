import React, { Component } from 'react'
import Markdown from 'markdown-to-jsx';

export class TextColumn extends Component {

  constructor() {
    super();
    this.state = {
      text: {},
      message: null
    }
  }

  async componentDidMount() {
    await this.setState({
      text: this.props.texty
    })    

    let words = this.state.text.split(' ')

    // average reading is 320 p/m
    // so 320 per 60 seconds 
    // 320 / 60 = 5.33

    let wordsPerSecond = parseInt(words.length)
    wordsPerSecond = wordsPerSecond / 5.33
    let minutes = Math.floor(wordsPerSecond / 60);
    let seconds = Math.floor(wordsPerSecond - minutes * 60);

    let message = null

    if(minutes === 1 && seconds === 1 ) {
      message = `This took up ${minutes} minute and ${seconds} second of your life. Thanks for reading.`  
    } else if (minutes === 1) {
      message = `This took up ${minutes} minute and ${seconds} seconds of your life. Thanks for reading.`
    } else if (seconds === 1) {
      message = `This took up ${minutes} minute and ${seconds} seconds of your life. Thanks for reading.`
    } else {
      message = `This took up ${minutes} minutes and ${seconds} seconds of your life. Thanks for reading.`
    }

    this.setState({
      message: message
    })     
  }

  render() {
    // const {h1, h1up, h2, h2bold, p, link, navlink, captionlink} = this.props.type
    const { p } = this.props.type
    const { texty } = this.props
    
    return (
      <div className="w-80 w-50-l mt3">
        <span className={p}>
          <Markdown>{texty}</Markdown>
        </span>
        <span className={p}>
          {this.state.message}
        </span>
      </div>
    )
  }
}

export default TextColumn
