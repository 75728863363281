const blankitCopy = 
`
Blankit is an app that blocks out distractions and gets you into a flow state. Stop losing your best hours of the day by using this app to increase your productivity. Harness your focus by using sound scapes that block out other sounds and promote clarity. Break down work into intervals of 25 minutes in length, separated by 5 minute breaks - a time management method called Pomodoro technique.

Maximise your daily work output by putting yourself into a bubble. Using specially designed soundscapes, Blankit uses stylised white noise frequencies designed to maximise your focus.

The app is currently completely free. To celebrate November 2019 (Blade Runner month), Blankit features two tracks inspired by Blade Runner's dystopian landscape and also the SciFi classic, 2001: A Space Odyssey. These are soundscapes that not only block out distraction but enclose you in an auditory landscape.

WHAT DOES THIS APP DO FOR YOU?  
The primary motive of Blankit is to achieve flow. Flow state describes being immersed in an activity, to the point that you completely forget about your worries and anxieties. You look up after this state of flow, wondering where the time went.

Flow is where you achieve your best work. Tests have shown that getting back to where you were after being distracted can take 20 minutes. That only has to happen a few times a day to leave you feeling frustrated and underwhelmed by your day's productivity.

Using the Blankit sounds means you can be surrounded by people and noisy environments like cafes, parks or work, while still maintaining your complete focus.

AIMS  
- Achieve maximum daily output  
- Get things done quicker and free up your time to do the things you want to do  
- Get into a flow state and clear your todo lists  
- Work at your highest capacity  
- No more time consuming hunts on Youtube or Spotify in order to find the right song or sound to block out distractions  

WHAT YOU'LL GET  
- 2 tracks lasting 25 mins with 5 minute intervals  
- Sounds based on 2 classic Sci Fi films  
- Quotes from Stoic thinkers and philosophers to keep your motivation levels high.  

`

export default blankitCopy