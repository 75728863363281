import React, { Component } from 'react'
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
// import Type from './components/Type'
import Homepage from './pages/Homepage'
import Blankit from './pages/Blankit'
import BlankitPrivacy from './pages/BlankitPrivacy'
import '../src/scss/app.scss';
 
class App extends Component {
  constructor() {
    super()
    this.state = {
      type: {},
      toggled: false,
      navHeight: null
    }
  }

  componentDidMount() {
    this.setState({
      type: {
        h1:           'f3 f2-m f1-l lh-3 lh-1-l bold',
        h1up:         'f3 f2-m f1-l lh-3 lh-1-l bold ttu',
        h2:           'f5 f4-m f2-l lh-5 lh-4-m lh-2-l regular',
        h2bold:       'f5 f4-m f2-l lh-5 lh-4-m lh-2-l bold',
        p:            'f7 f6-m f5-l lh-7 lh-6-m lh-4-l regular',
        link:         'f7 f6-m f5-l lh-7 lh-6-m lh-4-l regular link dim db',
        navlink:      'f10 f7-l lh-8 lh-4-l letter-2 letter-1-l regular link ttu',
        captionlink:  'f12 f10-m f10-l lh-10 lh-9-m letter-3 letter-2-m regular link ttu',
      }
    })
  }

  dataFromNavigation = (navTrueOrFalse) => {
    this.setState({ 
      toggled: !this.state.toggled
    })
    
    this.state.toggled 
      ? 
        this.setState({ 
          navHeight: null
        }) 
        :
          this.setState({ 
            navHeight: 'vh-100 '
          }) 
  }


  render() {
    const {type, navHeight} = this.state
    return (
      <Router>
        <div className="ph10 ph30-l">
          <Navbar type={type} navHeight={navHeight} triggerAppStateNavToggle={this.dataFromNavigation} />
          <Switch>
            <Route exact path="/">
              <Homepage type={type} />
            </Route>
            <Route exact path="/blankit">
              <Blankit type={type} />
            </Route>
            <Route exact path="/blankit-privacy">
              <BlankitPrivacy type={type} />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
